<template>
  <div>
    <v-card v-if="portfolioGrowth">
      <v-card-title class="secondary white--text font-weight-light pa-3 ">
        <div class="d-flex">
          <v-icon class="mr-3" color="white">mdi-chart-bar</v-icon>
          <span class="subtitle-1 font-weight-light">Rent and Income</span>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <div class="chart-container">
            <DashboardPortfolioGrowth 
              :tableData="portfolioGrowth"
              class="chart" 
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DashboardPortfolioGrowth from '@/components/DashboardGrowthCharts/DashboardPortfolioGrowth.vue';

export default {
  name: 'rentAndIncomeCard',
  components: {
    DashboardPortfolioGrowth
  },
  computed: {
    portfolioGrowth()  {
      return this.$store.state.DashboardCards.portfolioGrowth
    }
  }
}
</script>

<style>

</style>