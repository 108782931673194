<template>
  <div>
    <v-card flat v-if="portfolioGrowth" class="mt-4 mx-auto">
      <v-card-title class="secondary white--text font-weight-light pa-3">
        <div class="d-flex">
          <v-icon class="mr-3" color="white">mdi-chart-bar</v-icon>
          <span class="subtitle-1 font-weight-light">Income</span>
        </div>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-row v-if="income" align="center">
          <v-col cols="12" sm="9">
            <!-- <IncomeChart 
              tableData="portfolioGrowth"
              class="chart" 
            /> -->
            <p class="grey--text">Income data currently unavailable</p>
          </v-col>
          <v-col class="mt-sm-n3" cols="12" sm="3">
            <v-row class="py-sm-3">
              <v-col
                class="text-center d-sm-flex flex-column justify-center"
                cols="6"
                sm="12"
              >
                <span class="text-h6 text-sm-h5 text-md-h6 text-lg-h5"
                  >This month</span
                >
                <div>
                  <div class="d-flex align-center justify-center">
                    <v-icon
                      v-if="income.ManagementFeesPerformanceCurrentMonth > 0"
                      color="green"
                      >mdi-arrow-up-bold</v-icon
                    >
                    <v-icon
                      v-else-if="
                        income.ManagementFeesPerformanceCurrentMonth < 0
                      "
                      color="red"
                      >mdi-arrow-down-bold</v-icon
                    >
                    <v-icon v-else color="orange"
                      >mdi-arrow-up-bold-outline</v-icon
                    >
                    <span class="text-h6"
                      >{{ income.ManagementFeesPerformanceCurrentMonth }}%
                    </span>
                  </div>
                  <span class="text-body-1 font-weight-light">{{
                    income.ThisMonth.DisplayManagementFees | removeDecimals
                  }}</span>
                </div>
              </v-col>
              <v-col
                class="text-center d-sm-flex flex-column justify-center"
                cols="6"
                sm="12"
              >
                <span class="text-h6 text-sm-h5 text-md-h6 text-lg-h5"
                  >Last month</span
                >
                <div>
                  <div class="d-flex align-center justify-center">
                    <v-icon
                      v-if="income.ManagementFeesPerformanceLastMonth > 0"
                      color="green"
                      >mdi-arrow-up-bold</v-icon
                    >
                    <v-icon
                      v-else-if="income.ManagementFeesPerformanceLastMonth < 0"
                      color="red"
                      >mdi-arrow-down-bold</v-icon
                    >
                    <v-icon v-else color="orange"
                      >mdi-arrow-up-bold-outline</v-icon
                    >
                    <span class="text-h6"
                      >{{ income.ManagementFeesPerformanceLastMonth }}%
                    </span>
                  </div>
                  <span class="text-body-1 font-weight-light">{{
                    income.LastMonth.DisplayManagementFees | removeDecimals
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>
        <!-- {{income }} -->
        <div class="d-flex justify-end">
          <v-btn color="grey" small text @click="rentDialog = true">
            <span>Rent details</span>
          </v-btn>
          <v-divider vertical></v-divider>
          <v-btn @click="incomeDialog = true" small color="grey" text>
            <span>income Details</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <SkeletonLoader v-else />

    <v-dialog
      v-model="incomeDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <IcomeDetailsCard @closeDialog="closeDialog" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rentDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card>
        <DashboardTenancyRentCollection @closeDialog="closeDialog" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import IncomeChart from "@/components/DashboardLayouts/AlternativeDashboardCards/IncomeChart.vue";
import IcomeDetailsCard from "@/components/DashboardCards/DashboardCardIncome.vue";

import DashboardTenancyRentCollection from "@/components/DashboardCards/DashboardTenancyRentCollection.vue";
import SkeletonLoader from "@/components/SkeletonLoaders/SkeletonLoaderCard.vue";

export default {
  name: "alternativeDashboardIncome",
  components: {
    // IncomeChart,
    IcomeDetailsCard,
    DashboardTenancyRentCollection,
    SkeletonLoader,
  },
  data() {
    return {
      incomeDialog: false,
      rentDialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.rentDialog = false;
      this.incomeDialog = false;
    },
  },
  computed: {
    ...mapState({
      portfolioGrowth: (state) => state.DashboardCards.portfolioGrowth,
      income: (state) => state.DashboardCards.income,
    }),
    lastSixMonths() {
      return this.portfolioGrowth.slice(1, 7);
    },
    labels() {
      const labelArray = this.lastSixMonths.map(
        (obj) => obj.Month.split(" ")[0]
      );
      labelArray.reverse();
      return labelArray;
    },
    shortMonths() {
      const labels = this.labels;
      const newLabels = labels.map((month) => {
        if (month.length <= 4) return month;
        if (month === "September") return "Sept";
        return month.slice(0, 3);
      });
      return newLabels;
    },
    incomeValues() {
      const labelArray = this.lastSixMonths.map((obj) => obj.Fees);
      labelArray.reverse();
      return labelArray;
    },
  },
  filters: {
    removeDecimals(val) {
      const splitString = val.split(".");
      return splitString[0];
    },
  },
};
</script>

<style>
.chart {
  height: 253px;
}

.offset {
  position: absolute;
  top: -12px;
  left: 12px;
}

.v-sheet--offset {
  top: -12px;
  position: relative;
}
</style>