<template>
    <BaseDashboardInfoCard :linkTo="linkTo" v-if="outGoingPayments">

      <template slot="icon">
        mdi-arrow-up-bold-box-outline
      </template>

      <span slot="title">Outgoing payments</span>

      <template slot="table">
        <tr>
          <td class="pr-2">{{outGoingPayments.TotalPayments.NoOfPayments}}</td>
          <td class="px-0">Payments</td>
          <td class="px-0">{{outGoingPayments.TotalPayments.DisplayPaymentValue}}</td>
        </tr>
        <tr>
          <td class="pr-2">{{outGoingPayments.PaymentsOnHold.NoOfPayments}}</td>
          <td class="px-0">On hold</td>
          <td class="px-0">{{outGoingPayments.PaymentsOnHold.DisplayPaymentValue}}</td>
        </tr>
        <tr>
          <td class="pr-2">{{outGoingPayments.PaymentsNotOnHold.NoOfPayments}}</td>
          <td class="px-0">Not on hold</td>
          <td class="px-0">{{outGoingPayments.PaymentsNotOnHold.DisplayPaymentValue}}</td>
        </tr>
      </template>

    </BaseDashboardInfoCard>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data () {
    return {
      linkTo: '/agentpay/outgoing'
    }
  },
  computed: {
    ...mapState({
      outGoingPayments: state => state.DashboardCards.outGoingPayments   
    })
  },
}
</script>

<style scoped>

</style>
