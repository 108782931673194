<template>
  <div>
    <v-card flat :loading="loadingData" class="d-flex pt-1 pb-4">
      <v-card-text>
        <!-- Properties -->
        <SideCardSlot
          v-if="properties"
          :link="'/agentpay/properties'"
        >
          <template v-slot:value>
            {{ properties.managed }}
          </template>

          <template v-slot:title>
            Properties
          </template>
        </SideCardSlot>

        <v-divider class="my-4"></v-divider>

        <!-- Tenants -->
        <SideCardSlot
          v-if="tenants"
          :link="'/agentpay/tenants'"
        >
          <template v-slot:value>
            {{ tenants.inArrears.NoOfPayments }}
          </template>

          <template v-slot:title>
            <span class="font-weight-light">In arrears </span>Tenants
          </template>
        </SideCardSlot>

        <v-divider class="my-4"></v-divider>

        <!-- Landlords -->
        <SideCardSlot
          v-if="landlords"
          :link="'/agentpay/landlords'"
        >
          <template v-slot:value>
            {{ landlords.owing.NoOfPayments }}
          </template>

          <template v-slot:title>
            <span class="font-weight-light">In arrears </span>Landlords
          </template>
        </SideCardSlot>

        <v-divider class="my-4"></v-divider>

        <!-- Contractors -->
        <SideCardSlot
          v-if="contractors"
          :link="'/agentpay/contractors'"
        >
          <template v-slot:value>
            {{ contractors.onHold }}
          </template>

          <template v-slot:title>
            <span class="font-weight-light">On hold </span>Contractors
          </template>
        </SideCardSlot>

        <v-divider class="my-4"></v-divider>

        <!-- Insights -->
        <div v-if="insights" class="mb-n1">
          <div class="d-flex align-center justify-space-between align-center">
            <v-card
              vif 
              flat
              width="50" height="50"
              class="rounded-circle pa-3 ma-0 d-flex align-center justify-center"
            >
              <span class="font-weight-light text-body-2 text-sm-body-1">{{ managementFeeShort }}%</span>
            </v-card>
            <div class="ml-auto text-right">
              <p class="my-0 mr-3 text-h6 text-md-body-1 font-weight-light">Avg. Management fee</p>
            </div>
          </div>
          <div class="mt-n1 d-flex align-center justify-space-between align-center">
            <v-card 
              flat
              width="50" height="50"
              class="rounded-circle pa-3 ma-0 d-flex align-center justify-center"
            >
              <span class="font-weight-light text-body-2 text-sm-body-1">{{ insights.LandlordsWithZeroPercentFee }}</span>
            </v-card>
            <div class="ml-auto text-right">
              <p class="my-0 mr-3 text-h6 text-md-body-1 font-weight-light">Landlords 0% fee</p>
            </div>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SideCardSlot from '@/components/DashboardLayouts/AlternativeDashboardCards/SideCardSlot.vue';

export default {
  name: 'alternativeDashboardProperties',
  components: {
    SideCardSlot,
  },
  computed: {
    ...mapState({
      properties: state => state.PropertiesCount.properties,
      tenants: state => state.TenantsCount.tenants,
      landlords: state => state.LandlordsCount.landlords,
      contractors: state => state.ContractorsCount.contractors,
      insights: state => state.DashboardCards.insights,  
    }),
    managementFeeShort() {
      return this.insights.AverageManagementFeePercent.toFixed(1)
    },
    loadingData() {
      if(!this.properties || !this.tenants || !this.landlords || !this.contractors || !this.insights) return true
      return false
    }
  },
}
</script>

