<template>
  <div v-if="portfolioGrowth.length">
    <v-card :loading="!portfolioGrowth.length" >
      <v-card-title class="secondary white--text font-weight-light pa-3">
        <div class="d-flex">
          <v-icon class="mr-3" color="white">mdi-file-chart-outline</v-icon>
          <span class="subtitle-1 font-weight-light">Tenancy Rent Collection</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn 
          v-if="$store.state.alternativeDashboard" 
          @click="closeTableDialog"
          color="white" 
          fab small text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        
        hide-default-footer
        :headers="headers"
        :items="portfolioGrowth"
        :items-per-page="12"
        class="pt-5"
      >

      <template v-slot:body.prepend="{ headers }">
        <tr class="d-none d-sm-table-row">
          <td :colspan="headers.length/5"></td>
          <td :colspan="headers.length/5"></td>
          <td :colspan="headers.length/5">
            <span class="font-weight-light"><span class="caption font-weight-thin">Avg:</span> {{$store.getters.getRentRollPerformanceAverage | toFixed}}%</span>
          </td>
          <td :colspan="headers.length/5"></td>
          <td :colspan="headers.length/5">
            <span class="font-weight-light"><span class="caption font-weight-thin">Avg:</span> {{$store.getters.getPropertyCountAverage | toFixed}}%</span>
          </td>
        </tr>    
      </template>

      <template v-slot:item.RentRollPerformance="{ item }">
        <div v-if="item != portfolioGrowth[0]">
          <span :class="getColor(item.RentRollPerformance)">
            {{item.RentRollPerformance}}
            <span v-if="item.RentRollPerformance">%</span>
            <span v-else>-</span>
          </span>
          <v-icon v-if="item.RentRollPerformance > 0" color="green" small>mdi-arrow-up</v-icon>
          <v-icon v-if="item.RentRollPerformance < 0" color="red" small>mdi-arrow-down</v-icon>
          <v-icon v-if="item.RentRollPerformance == 0" color="green" small></v-icon>
        </div>
      </template>

      <template v-slot:item.PropertyCountPerformance="{ item }">
        <div v-if="item != portfolioGrowth[0]">
          <span :class="getColor(item.PropertyCountPerformance)">
            {{item.PropertyCountPerformance}}
            <span v-if="item.PropertyCountPerformance">%</span>
            <span v-else>-</span>
          </span>
          <v-icon v-if="item.PropertyCountPerformance > 0" color="green" small>mdi-arrow-up</v-icon>
          <v-icon v-if="item.PropertyCountPerformance < 0" color="red" small>mdi-arrow-down</v-icon>
          <v-icon v-if="item.PropertyCountPerformance == 0" color="green" small></v-icon>
        </div>
          
      </template>

      </v-data-table>
    </v-card>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      headers: [
        {
          text: 'Month',
          align: 'left',
          sortable: false,
          value: 'Month',
        },
        { text: 'Amount', value: 'DisplayRentRoll', align: 'left' },
        { text: '+/-', value: 'RentRollPerformance', align: 'left' },
        { text: 'Properties', value: 'PropertyCount', align: 'left' },
        { text: '+/-', value: 'PropertyCountPerformance', align: 'left' },

    ],
  }
},
  computed: {
    ...mapState({
      portfolioGrowth: state => state.DashboardCards.portfolioGrowth,
    }),
  },
  methods: {
    getColor(value) {
      if(value >= 0) return 'green--text'
      else if(value < 0) return 'red--text'
    },
    closeTableDialog() {
      this.$emit('closeDialog')
    }
  },
  filters: {
    toFixed(value) {
      return value.toFixed(1)
    }
  }
}
</script>
