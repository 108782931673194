<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div>
        <v-row class="mt-1">
          <v-col cols="12">
            <v-row align="center">
              <v-col cols="6" sm="8">
                <span
                  class="font-weight-regular grey--text text-body-1 text-sm-h6"
                >
                  {{ todaysDate }}
                </span>
              </v-col>
              <v-col cols="6" sm="4" class="d-flex flex-wrap align-center">
                <v-select
                  dense
                  outlined
                  v-model="$store.state.branchID"
                  class="font-weight-light ml-auto mb-n6"
                  color="accent"
                  item-color="accent"
                  v-if="$store.state.multiBranchAccess"
                  return-object
                  :items="allBranches"
                  item-text="Name"
                  item-value="OID"
                  @change="selectNewBranch"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-6 pb-6">
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6">
                <transition name="slide-right-fade-delay" mode="out-in" appear>
                  <AlternativeDashboardUnreconciled />
                </transition>
              </v-col>
              <v-col cols="12" md="6">
                <transition name="slide-down-fade" mode="out-in" appear>
                  <AlternativeDashboardOutgoing />
                </transition>
              </v-col>
              <v-col class="mt-n6" cols="12">
                <transition name="slide-up-fade" mode="out-in" appear>
                  <AlternativeDashboardIncome />
                </transition>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12">
                <transition name="slide-left-fade" mode="out-in" appear>
                  <AlternativeDashboardSideCard />
                </transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment/src/moment";

import AlternativeDashboardUnreconciled from "@/components/DashboardLayouts/AlternativeDashboardCards/AlternativeDashboardUnreconciled.vue";
import AlternativeDashboardOutgoing from "@/components/DashboardLayouts/AlternativeDashboardCards/AlternativeDashboardOutgoing.vue";
import AlternativeDashboardIncome from "@/components/DashboardLayouts/AlternativeDashboardCards/AlternativeDashboardIncome.vue";
import AlternativeDashboardSideCard from "@/components/DashboardLayouts/AlternativeDashboardCards/AlternativeDashboardSideCard.vue";

export default {
  name: "alternativeDashboard",
  components: {
    AlternativeDashboardUnreconciled,
    AlternativeDashboardOutgoing,
    AlternativeDashboardIncome,
    AlternativeDashboardSideCard,
  },
  computed: {
    ...mapState({
      portfolioGrowth: (state) => state.DashboardCards.portfolioGrowth,
      unreconciledPayments: (state) =>
        state.DashboardCards.unreconciledPayments,
      outGoingPayments: (state) => state.DashboardCards.outGoingPayments,
      insights: (state) => state.DashboardCards.insights,
      landlords: (state) => state.LandlordsCount.landlords,
      tenants: (state) => state.TenantsCount.tenants,
      properties: (state) => state.PropertiesCount.properties,
      contractors: (state) => state.ContractorsCount.contractors,
      income: (state) => state.DashboardCards.income,
      allBranches: (state) => state.allBranches,
    }),
    todaysDate() {
      return moment().format("Do MMMM YYYY");
    },
  },
  methods: {
    selectNewBranch(obj) {
      this.$emit("newBranchSelection", obj);
    },
  },
};
</script>

<style>
</style>