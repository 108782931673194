<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <v-btn 
        :to="link"
        color="grey" 
        outlined
        min-width="60" min-height="60" 
        fab 
        class=" pa-3 ma-0 d-flex align-center justify-center"
      >
        <span class="grey--text text--darken-1 font-weight-light text-h5 text-md-h6">
          <slot name="value"></slot>
        </span>
      </v-btn>
      <div class="ml-auto text-right">
        <p class="my-0 mr-3 text-h6 text-sm-h4 text-md-body-1 text-lg-h6 font-weight-regular">
          <slot name="title"></slot>
        </p>
        <div>
          <v-btn @click="dialog = true" class="mt-3 mt-md-0" small text>
            <span class="grey--text font-weight-light text-caption">details</span>
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <DashboardCardProperties v-if="link === '/agentpay/properties'" />
      <DashboardCardTenants v-if="link === '/agentpay/tenants'" />
      <DashboardCardLandlords v-if="link === '/agentpay/landlords'" />
      <DashboardCardContractors v-if="link === '/agentpay/contractors'" />
    </v-dialog>
  </div>
</template>

<script>
import DashboardCardProperties from '@/components/DashboardCards/DashboardCardProperties.vue';
import DashboardCardTenants from '@/components/DashboardCards/DashboardCardTenants.vue';
import DashboardCardLandlords from '@/components/DashboardCards/DashboardCardLandlords.vue';
import DashboardCardContractors from '@/components/DashboardCards/DashboardCardContractors.vue';

export default {
  name: 'side-card-slot',
  props: {
    link: String,
  },
  components: {
    DashboardCardProperties,
    DashboardCardTenants,
    DashboardCardLandlords,
    DashboardCardContractors
  },
  data() {
    return {
      dialog: false
    }
  },
}
</script>

<style>

</style>