<template>
  <div>
    <v-card flat class="d-flex">
      <v-card-text>
        <v-btn 
          to="/agentpay/outgoing"
          color="secondary" 
          min-width="80"
          height="80"
          class="offset pa-3 ma-0 d-flex align-center justify-center"
        >
          <span v-if="outGoingPayments" class="white--text text-h4">
            {{ outGoingPayments.PaymentsOnHold.NoOfPayments }}
          </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
        <div class="ml-auto text-right">
          <span class="d-md-none d-lg-block text-h6 text-sm-h4 text-md-h6"><span class="font-weight-light">On hold </span> Payments</span>
          <span class="d-none d-md-block d-lg-none text-h6 text-sm-h4 text-md-h6">On hold</span>
          <p v-if="outGoingPayments" class="text-h6 font-weight-light">{{ outGoingPayments.PaymentsOnHold.DisplayPaymentValue }}</p>
          <v-progress-circular
            class="my-2"
            v-else
            width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <v-divider class="mb-3"></v-divider>
        <div class="d-flex justify-end">
          <!-- <v-btn small to="/agentpay/unreconciled" color="secondary" text>
            <span class="grey--text">Tables</span>
          </v-btn> -->
          <v-btn @click="dialog = true" small text>
            <span class="grey--text">Details</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <DashboardCardOutgoing />
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DashboardCardOutgoing from '@/components/DashboardCards/DashboardCardOutgoing.vue';

export default {
  name: 'alternativeDashboardOutgoing',
  components: {
    DashboardCardOutgoing
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState({
      outGoingPayments: state => state.DashboardCards.outGoingPayments,
    })
  },
}
</script>

<style scoped>
.offset {
  position: absolute;
  top:  -12px;
  left: 12px;
}
</style>