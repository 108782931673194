<template>
  <BaseDashboardInfoCard :linkTo="linkTo" v-if="tenants">

    <template slot="icon">
      mdi-home-account
    </template>

    <span slot="title">Tenants</span>

    <template slot="table">
      <tr>
        <td class="pr-2">{{tenants.active}}</td>
        <td class="px-0">Active tenants</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="pr-2">{{tenants.inArrears.NoOfPayments}}</td>
        <td class="px-0">In arrears</td>
        <td class="px-0">{{tenants.inArrears.DisplayPaymentValue}}</td>
      </tr> 
      <tr> 
        <td class="pr-2">{{tenants.notChased.NoOfPayments}}</td>
        <td class="px-0">Not chased</td>
        <td class="px-0">{{tenants.notChased.DisplayPaymentValue}}</td>
      </tr>
      <tr> 
        <td class="pr-2">{{tenants.historic}}</td>
        <td class="px-0">Historic tenants</td>
        <td class="px-0"></td>
      </tr>
    </template>

  </BaseDashboardInfoCard>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data () {
    return {
      linkTo: '/agentpay/tenants'

    }
  },
  computed: {
    ...mapState({
      tenants: state => state.TenantsCount.tenants   
    })
  },
}
</script>
