<template>
  <div class="">
    <!-- <h2 class="subtitle-1 grey--text text--darken-3">Dashboard</h2> -->

    <CircularOverlay
      :overlay="overlayLoading"
    />

    <v-container class="py-0">
      <DefaultDashboard 
        v-if="!showAlternativeDashboard"
        @newBranchSelection="newBranchSelection" 
      />
      <AlternativeDashboard 
        v-else
        @newBranchSelection="newBranchSelection" 
      />

    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DefaultDashboard from '@/components/DashboardLayouts/DefaultDashboard.vue';
import AlternativeDashboard from '@/components/DashboardLayouts/AlternativeDashboard.vue';

export default {
  components: {
    DefaultDashboard,
    AlternativeDashboard
  },
  data() {
    return {
      overlayLoading: false,
    }
  },
  created() {
    this.$store.dispatch('fetchDashboardData')
  },
  computed: {
    ...mapState({
      showAlternativeDashboard: state => state.alternativeDashboard,
      portfolioGrowth: state => state.DashboardCards.portfolioGrowth,
      unreconciledPayments: state => state.DashboardCards.unreconciledPayments,
      outGoingPayments: state => state.DashboardCards.outGoingPayments,
      insights: state => state.DashboardCards.insights,
      landlords: state => state.DashboardCards.outGoingPayments,
      tenants: state => state.TenantsCount.tenants,
      properties: state => state.PropertiesCount.properties,
      contractors: state => state.ContractorsCount.contractors,
      income: state => state.DashboardCards.income,
      allBranches: state => state.allBranches
    })
  },
  methods: {
    newBranchSelection(obj) {
      this.$store.dispatch('setSelectedBranch', obj)
      .then( () => {
        this.overlayLoading = true
        this.$store.dispatch('fetchDashboardData')
      })
    }
  },
  mounted() {
    // Setting username for chat plugin
    const activeUser = this.$store.state.activeUser
    const userName = this.$store.state.user.ClientName
    $zopim(function(){ // eslint-disable-line
      $zopim.livechat.setName(activeUser) // eslint-disable-line
      $zopim.livechat.addTags(userName) // eslint-disable-line
    })
  },
  watch: {
    portfolioGrowth(oldVal, newVal) {
      if(newVal !== oldVal) {
        this.overlayLoading = false
      }
    },
    unreconciledPayments(oldVal, newVal) {
      if(newVal !== oldVal) {
        this.overlayLoading = false
      }
    },
    outGoingPayments(oldVal, newVal) {
      if(newVal !== oldVal) {
        this.overlayLoading = false
      }
    },
  },
}
</script>

<style scoped>
  .chart {
    height: 253px;
  }
  .chart-2 {
    height: 263px;
  }
</style>

