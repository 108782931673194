<template>
  <div>
    <v-card flat class="d-flex" >
      <v-card-text>
        <v-btn 
          to="/agentpay/unreconciled"
          color="secondary" 
          min-width="80"
          height="80"
          class="offset pa-3 ma-0 d-flex align-center justify-center"
        >
          <span v-if="unreconciledPayments" class="white--text text-h4">
            {{ unreconciledPayments.UnreconciledPayments }}
          </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
        <div class="ml-auto text-right">
          <span class="d-md-none d-lg-block text-h6 text-sm-h4 text-md-h6"><span class="font-weight-light">Unreconciled </span> Payments</span>
          <span class="d-none d-md-block d-lg-none text-h6 text-sm-h4 text-md-h6">Unreconciled</span>
          <p v-if="unreconciledPayments" class="text-h6 font-weight-light">{{ unreconciledPayments.DisplayUnreconciledPaymentsAmount }}</p>
          <v-progress-circular
            class="my-2"
            v-else
            width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <v-divider class="mb-3"></v-divider>
        <div class="d-flex justify-end">
          <v-btn disabled small text>
            <span class="grey--text font-weight-light custom-transform-class text-none">Updated {{ updatedAgo }}</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment/src/moment'

export default {
  name: 'alternativeDashboardUnreconciled',
  data() {
    return {
      lastUpdated: '',
      updatedAgo: '',
      timeAgoInterval: ''
    }
  },
  created() {
    this.lastUpdated = moment()
    this.updatedAgo = moment(`${this.lastUpdated}`).fromNow()
    this.setTimeAgoInterval()
  },
  methods: {
    setTimeAgoInterval() {
      const self = this
      this.timeAgoInterval = setInterval(function() {
        console.log('Updating the updated time!')
        self.updatedAgo = moment(`${self.lastUpdated}`).fromNow()
      }, 60000)
    }
  },
  computed: {
    ...mapState({
      unreconciledPayments: state => state.DashboardCards.unreconciledPayments,
    }),
  },
  destroyed() {
    clearInterval(this.timeAgoInterval)
  }
}
</script>

<style scoped>
.offset {
  position: absolute;
  top:  -12px;
  left: 12px;
}
</style>