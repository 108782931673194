<template>
  <BaseDashboardInfoCard :linkTo="linkTo" >
    <template slot="icon">
      mdi-transfer
    </template>
    <span slot="title">Unreconciled payments</span>
    <template slot="table">
      <tr>
        <td class="pr-2">{{ unreconciled.UnreconciledPayments}}</td>
        <td class="px-0">Payments</td>
        <td class="px-0">{{ unreconciled.DisplayUnreconciledPaymentsAmount}}</td>
      </tr>
    </template>
  </BaseDashboardInfoCard>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data () {
    return {
      linkTo: '/agentpay/unreconciled'
    }
  },
  computed: {
    ...mapState({
      unreconciled: state => state.DashboardCards.unreconciledPayments
    })
  },
}
</script>

<style scoped>

</style>
