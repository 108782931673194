<template>
  <BaseDashboardInfoCard :linkTo="linkTo">

    <template slot="icon">
      mdi-home
    </template>

    <span slot="title">Properties</span>

    <template slot="table">
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.managed}}</td>
        <td class="" width="75%">Active tenancies</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.collected}}</td>
        <td class="" width="75%">Rent collection properties</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.newTenancies}}</td>
        <td class="" width="75%">New tenancies</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.ownerless}}</td>
        <td class="" width="75%">Without owners</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.tenantless}}</td>
        <td class="" width="75%">Without tenants</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.unmanaged}}</td>
        <td class="" width="75%">Not managed</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{$store.state.PropertiesCount.properties.historicTenancies}}</td>
        <td class="" width="75%">Historic tenancies</td>
        <td class=""></td>
      </tr>

    </template>
  </BaseDashboardInfoCard>
</template>

<script>
export default {
  data () {
    return {
      linkTo: '/agentpay/properties'

    }
  },
}
</script>
