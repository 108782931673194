<template>
  <v-card v-if="income">
    <v-card-title class="secondary white--text font-weight-light pa-3">
      <div class="d-flex">
        <v-icon class="mr-3" color="white">mdi-finance</v-icon>
        <span class="subtitle-1 font-weight-light">Income</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn 
        v-if="$store.state.alternativeDashboard" 
        @click="closeTableDialog"
        color="white" 
        fab small text>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Period</th>
          <th class="text-center">Management fees</th>
          <th class="text-center">Commissions</th>
          <th class="text-center">Other incomes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="">This month</td>
          <td class="pt-3 text-center">
            <span>{{ income.ThisMonth.DisplayManagementFees }}</span>
            <p class="caption" :class="getColor(income.ManagementFeesPerformanceCurrentMonth)">
              <v-icon x-small :color="getIconColor(income.ManagementFeesPerformanceCurrentMonth)">
                {{ getIcon(income.ManagementFeesPerformanceCurrentMonth) }}
              </v-icon>
              {{ income.ManagementFeesPerformanceCurrentMonth }}%
            </p>
          </td>
          <td class="pt-3 text-center">
            {{ income.ThisMonth.DisplayCommissions }}
            <p class="caption" :class="getColor(income.CommissionPerformanceCurrentMonth)">
              <v-icon x-small :color="getIconColor(income.CommissionPerformanceCurrentMonth)">
                {{ getIcon(income.CommissionPerformanceCurrentMonth) }}
              </v-icon>
              {{ income.CommissionPerformanceCurrentMonth }}%
            </p>
          </td>
          <td class="pt-3 text-center">
            {{ income.ThisMonth.DisplayOtherIncomes }}
            <p class="caption" :class="getColor(income.OtherPerformanceCurrentMonth)">
              <v-icon x-small :color="getIconColor(income.OtherPerformanceCurrentMonth)">
                {{ getIcon(income.OtherPerformanceCurrentMonth) }}
              </v-icon>
              {{ income.OtherPerformanceCurrentMonth }}%
            </p>
          </td>
        </tr>
        <!-- Last month -->
        <tr class="grey--text">
          <td>Last month</td>
          <td class="pt-3 text-center">
            <span>{{ income.LastMonth.DisplayManagementFees }}</span>
            <p class="caption" :class="getColor(income.ManagementFeesPerformanceLastMonth)">
              <v-icon x-small :color="getIconColor(income.ManagementFeesPerformanceLastMonth)">
                {{ getIcon(income.ManagementFeesPerformanceLastMonth) }}
              </v-icon>
              {{ income.ManagementFeesPerformanceLastMonth }}%
            </p>
          </td>
          <td class="pt-3 text-center">
            {{ income.LastMonth.DisplayCommissions }}
            <p class="caption" :class="getColor(income.CommissionPerformanceLastMonth)">
              <v-icon small :color="getIconColor(income.CommissionPerformanceLastMonth)">
                {{ getIcon(income.CommissionPerformanceLastMonth) }}
              </v-icon>
              {{ income.CommissionPerformanceLastMonth }}%
            </p>
          </td>
          <td class="pt-3 text-center">
            {{ income.LastMonth.DisplayOtherIncomes }}
            <p class="caption" :class="getColor(income.OtherPerformanceLastMonth)">
              <v-icon small :color="getIconColor(income.OtherPerformanceLastMonth)">
                {{ getIcon(income.OtherPerformanceLastMonth) }}
              </v-icon>
              {{ income.OtherPerformanceLastMonth }}%
            </p>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState({
      income: state => state.DashboardCards.income
    })
  },
  methods: {
    getColor(value) {
      if(value >= 0) return 'green--text'
      else if(value < 0) return 'red--text'
    },
    getIconColor(value) {
      if(value >= 0) return 'green'
      else if(value < 0) return 'red'
    },
    getIcon(value) {
      if(value == 0) return ''
      else if(value >= 0) return 'mdi-arrow-up'
      else if(value < 0) return 'mdi-arrow-down'
    },
    closeTableDialog() {
      this.$emit('closeDialog')
    }
  },
}
</script>
