<template>
    <BaseDashboardInfoCard :linkTo="linkTo" v-if="landlords">
      <template slot="icon">
        mdi-account-tie
      </template>
      <span slot="title">Landlords</span>
      <template slot="table">
        <tr>
          <td class="pr-2">{{landlords.owing.NoOfPayments}}</td>
          <td class="px-0">In arrears</td>
          <td class="px-0">{{landlords.owing.DisplayPaymentValue}}</td>
        </tr>
        <tr>
          <td class="pr-2">{{landlords.emailless}}</td>
          <td class="px-0">Without e-mail</td>
          <td class=""></td>
        </tr>
        <tr>
          <td class="pr-2">{{landlords.bankless}}</td>
          <td class="px-0">No bank details</td>
          <td class=""></td>
        </tr>
      </template>
    </BaseDashboardInfoCard>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data () {
    return {
      linkTo: '/agentpay/landlords'
    }
  },
  computed: {
    ...mapState({
      landlords: state => state.LandlordsCount.landlords   
    })
  },
}
</script>
