<template>
  <BaseDashboardInfoCard :linkTo="linkTo" v-if="contractors">
    <template slot="icon">
      mdi-tools
    </template>
    <span slot="title">Contractors</span>
    <template slot="table">
      <tr>
        <td class="">{{contractors.bankless}}</td>
        <td class="" width="85%">No bank details</td>
      </tr>      
      <tr>
        <td class="">{{contractors.emailless}}</td>
        <td class="" width="85%">Without e-mail</td>
      </tr>      
      <tr>
        <td class="">{{contractors.onHold}}</td>
        <td class="" width="85%">On hold</td>
      </tr>
    </template>
  </BaseDashboardInfoCard>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      linkTo: '/agentpay/contractors'

    }
  },
  computed: {
    ...mapState({
      contractors: state => state.ContractorsCount.contractors
    })
  },
}
</script>
