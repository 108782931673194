<template>
  <BaseDashboardInfoCard :linkTo="linkTo" class="green lighten-1" v-if="insights">
    <template slot="icon">
      mdi-book-search-outline
    </template>
    <span slot="title">Insights</span>
    <template slot="table">
      <tr>
        <td class="">{{insights.AverageManagementFeePercent | toFixed }}%</td>
        <td class="pr-0" width="85%">Avg. management fee</td>
        <td class=""></td>
      </tr>
      <tr>
        <td class="">{{insights.LandlordsWithZeroPercentFee}}</td>
        <td class="pr-0" width="85%">Landlords 0% fee</td>
        <td class=""></td>
      </tr>
    </template>
  </BaseDashboardInfoCard>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      linkTo: '#'
    }
  },
  computed: {
    ...mapState({
      insights: state => state.DashboardCards.insights   
    })
  },
  filters: {
    toFixed(value) {
      return value.toFixed(2)
    }
  }
}
</script>
