<template>
  <div class="">
    <transition name="fade" mode="out-in" appear>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="6" sm="8">
              <transition name="slide-down-fade" mode="out-in" appear>
                <div>
                  <span
                    class="
                      font-weight-regular
                      grey--text
                      text-body-1 text-sm-h6
                    "
                    >{{ todaysDate }}</span
                  >
                </div>
              </transition>
            </v-col>
            <v-col cols="6" sm="4" class="d-flex align-center">
              <transition name="slide-down-fade" mode="out-in" appear>
                <div>
                  <v-select
                    dense
                    outlined
                    v-model="$store.state.branchID"
                    class="font-weight-light mb-n6 ml-lg-3"
                    color="accent"
                    item-color="accent"
                    v-if="$store.state.multiBranchAccess"
                    return-object
                    :items="allBranches"
                    item-text="Name"
                    item-value="OID"
                    @change="selectNewBranch"
                  ></v-select>
                </div>
              </transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </transition>

    <!-- Dashboard cards -->
    <transition name="slide-fade" mode="out-in" appear>
      <v-row class="mt-3">
        <v-col class="" cols="12" sm="4" lg="4">
          <v-row>
            <v-col cols="12">
              <transition name="slide-right-fade" mode="out-in" appear>
                <div>
                  <DashboardCardUnreconciled v-if="unreconciledPayments" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <v-col cols="12">
              <transition name="slide-right-fade-delay" mode="out-in" appear>
                <div>
                  <DashboardCardOutgoing v-if="outGoingPayments" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <v-col cols="12">
              <transition name="slide-right-fade-delay2" mode="out-in" appear>
                <div>
                  <DashboardCardTenants v-if="tenants" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <v-col cols="12">
              <transition name="slide-up-fade-delay" mode="out-in" appear>
                <div>
                  <DashboardCardProperties v-if="properties" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <v-col cols="12">
              <DashboardCardInsights v-if="insights" />
              <SkeletonLoader v-else />
            </v-col>
            <!-- <v-col class="d-none d-sm-block" cols="12">
                <v-card>
                  <div class="twitter-wrapper">
                    <Timeline id="AgentMarketPl" sourceType="profile" />
                  </div>
                </v-card>
            </v-col> -->
          </v-row>
        </v-col>

        <v-col cols="12" sm="8" lg="8">
          <v-row>
            <v-col cols="12">
              <transition name="slide-left-fade" mode="out-in" appear>
                <div>
                  <RentAndIncome v-if="portfolioGrowth" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <v-col cols="12" sm="6" lg="6">
              <transition name="slide-up-fade" mode="out-in" appear>
                <div>
                  <DashboardCardLandlords v-if="landlords" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <v-col cols="12" sm="6" lg="6">
              <transition name="slide-left-fade-delay" mode="out-in" appear>
                <div>
                  <DashboardCardContractors v-if="contractors" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col>
            <!-- <v-col cols="12">
              <transition name="slide-left-fade" mode="out-in" appear>
                <div>
                  <DashboardCardIncome v-if="income" />
                  <SkeletonLoader v-else />
                </div>
              </transition>
            </v-col> -->
            <v-col cols="12">
              <DashboardTenancyRentCollection
                class="tenancy-rent-table"
                v-if="portfolioGrowth"
              />
              <SkeletonLoader v-else />
            </v-col>
            <!-- <v-col class="d-sm-none" cols="12">
              <v-card>
                <div class="twitter-wrapper">
                  <Timeline id="AgentMarketPl" sourceType="profile" />
                </div>
              </v-card>
          </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment/src/moment";

// import { Timeline } from 'vue-tweet-embed'

import DashboardCardUnreconciled from "@/components/DashboardCards/DashboardCardUnreconciled.vue";
import DashboardCardOutgoing from "@/components/DashboardCards/DashboardCardOutgoing.vue";
import DashboardCardInsights from "@/components/DashboardCards/DashboardCardInsights.vue";
import DashboardCardLandlords from "@/components/DashboardCards/DashboardCardLandlords.vue";
import DashboardCardTenants from "@/components/DashboardCards/DashboardCardTenants.vue";
import DashboardCardProperties from "@/components/DashboardCards/DashboardCardProperties.vue";
import RentAndIncome from "@/components/DashboardCards/DashboardRentAndIncome.vue";
import DashboardTenancyRentCollection from "@/components/DashboardCards/DashboardTenancyRentCollection.vue";
// import DashboardCardIncome from "@/components/DashboardCards/DashboardCardIncome.vue";
import DashboardCardContractors from "@/components/DashboardCards/DashboardCardContractors.vue";
import SkeletonLoader from "@/components/SkeletonLoaders/SkeletonLoaderCard.vue";

export default {
  name: "defaultDashboard",
  components: {
    DashboardCardUnreconciled,
    DashboardCardOutgoing,
    DashboardCardInsights,
    DashboardCardLandlords,
    DashboardCardTenants,
    DashboardCardProperties,
    RentAndIncome,
    DashboardTenancyRentCollection,
    // DashboardCardIncome,
    DashboardCardContractors,
    SkeletonLoader,
    // Timeline
  },
  computed: {
    ...mapState({
      portfolioGrowth: (state) => state.DashboardCards.portfolioGrowth,
      unreconciledPayments: (state) =>
        state.DashboardCards.unreconciledPayments,
      outGoingPayments: (state) => state.DashboardCards.outGoingPayments,
      insights: (state) => state.DashboardCards.insights,
      landlords: (state) => state.LandlordsCount.landlords,
      tenants: (state) => state.TenantsCount.tenants,
      properties: (state) => state.PropertiesCount.properties,
      contractors: (state) => state.ContractorsCount.contractors,
      income: (state) => state.DashboardCards.income,
      allBranches: (state) => state.allBranches,
    }),
    todaysDate() {
      return moment().format("Do MMMM YYYY");
    },
  },
  methods: {
    selectNewBranch(obj) {
      this.$emit("newBranchSelection", obj);
    },
  },
};
</script>

<style scoped>
.chart {
  height: 253px;
}
.chart-2 {
  height: 263px;
}
</style>

