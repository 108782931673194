<!-- Dashboard Graph -->

<script>
import { Bar } from 'vue-chartjs'
import { mapState } from 'vuex'

export default {
  extends: Bar,
  data() {
    return {
      rentRollNumbers: [],
      nodes: null,
      chartdata: {
        labels: [],
        datasets: [
          {
            // backgroundColor: 'rgba(76,175,80, 0.7)',
            backgroundColor: 'rgba(52, 68, 81, 0.7)',
            label: 'Rent',
            data: null,
            yAxisID: 'y-axis-1'
          },
          {
            label: 'Income',
            backgroundColor: '#f26642',
            borderColor: '#f26642',
            borderWidth: 1,
            pointBackgroundColor: '#f26642',
            pointRadius: 5,
            data: null,
            type: 'line',
            yAxisID: 'y-axis-2'
          }
        ]
      },
      options: {
        legend: {
          display: true,
        },
        tooltips: {
            mode: 'index',
            intersect: true,
            callbacks: {
              label: function(tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                let value = parseInt(tooltipItem.value).toFixed(0)
                let newValue = parseFloat(value).toLocaleString('en-GB')

                if (label) {
                    label += ': £';
                }
                label += newValue
                return label;
              }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 2000
        },
        title: {
          display: false,
        },
        scales: {
          yAxes: [
            {
            display: true,
            position: 'left',
            id: 'y-axis-1',
            afterTickToLabelConversion(scaleInstance) {
              scaleInstance.ticks[0] = null;
              scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
              scaleInstance.ticksAsNumbers[0] = null;
              scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
            },
            ticks: {
              callback: function(label) {
                return label/1000+'k';
              },
              beginAtZero: true,
              min: null,
              // stepSize: null,
              // fontColor: 'rgba(76,175,80)',
              fontColor: 'rgba(52, 68, 81, 0.7)',
              maxTicksLimit: 10,
            },
            // scaleLabel: {
            //         display: true,
            //         labelString: '1k = 1000'
            //     },
            gridLines: {
              drawOnChartArea: true,
              // color: 'rgba(76,175,80, 0.7)',
              color: 'rgba(52, 68, 81, 0.7)',
            },
          },
          {
            display: true,
            position: 'right',
            id: 'y-axis-2',
            afterTickToLabelConversion(scaleInstance) {
              scaleInstance.ticks[0] = null;
              scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
              scaleInstance.ticksAsNumbers[0] = null;
              scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
            },
            ticks: {
              callback: function(label) {
                return label/1000+'k';
              },
              beginAtZero: true,
              fontColor: '#f26642',
              // stepSize: null,
              maxTicksLimit: 6,
            },
            gridLines: {
              drawOnChartArea: false,
              color: 'rgba(242, 102, 66, 0.3)'
            },
          }],
          xAxes: [{
            ticks: {
              display: false //this will remove only the label
            }
          }]
        },
        elements: {
          line: {
            fill: false,
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 1
          },
          rectangle: {
            backgroundColor: 'rgba(255, 141, 0, 0.8)'
          },
        }
      }
    }
  },
  computed: {
    ...mapState({
      portfolioGrowth: state => state.DashboardCards.portfolioGrowth,
      branchName: state => state.branchName
    }),
    rentRoll() {
      const rentRoll = this.portfolioGrowth.map(node => node.RentRoll)
      return rentRoll.reverse()
    },
    income() {
      const income = this.portfolioGrowth.map(node => node.Fees)
      return income.reverse()
    },
    labels() {
      const labels = this.portfolioGrowth.map(node => node.Month)
      return labels.reverse()
    }
  },
  mounted() {
    this.setChart()
    // this.chartdata.datasets[0].data = rentRoll.reverse()
    // this.chartdata.datasets[1].data = income.reverse()
    // this.chartdata.labels = labels.reverse()
    this.renderChart(this.chartdata, this.options)

  },
  methods: {
    setChart() {
        const rentRoll = this.rentRoll
        const income = this.income
        const labels = this.labels

        const minRentTickValue = (Math.min(...rentRoll)) / 2
        const maxRentValue = Math.max(...rentRoll)
        // const minRentValue = Math.min(...rentRoll)
        // const rentRollRange = (maxRentValue - minRentValue)
        // const rentRollStepSize = rentRollRange / 2

        const minIncomeTickValue = (Math.min(...income)) / 2
        const maxIncomeValue = Math.max(...income)
        // const minIncomeValue = Math.min(...income)
        // const incomeRange = (maxIncomeValue - minIncomeValue)
        // const incomeStepSize = incomeRange / 2

        this.options.scales.yAxes[0].ticks.min = minRentTickValue
        this.options.scales.yAxes[0].ticks.max = maxRentValue * 1.15

        this.options.scales.yAxes[1].ticks.min = minIncomeTickValue
        this.options.scales.yAxes[1].ticks.max = maxIncomeValue *1.15

        this.chartdata.datasets[0].data = rentRoll
        this.chartdata.datasets[1].data = income
        this.chartdata.labels = labels
    },
    // forceUpdate() {
    //   this.$store.dispatch('DashboardCards/getPortfolioGrowth')
    //   .then(nodes => {
    //     this.nodes = this.portfolioGrowth
    //     const rentRoll = this.nodes.map(node => node.RentRoll)
    //     const income = this.nodes.map(node => node.Fees)
    //     const labels = this.nodes.map(node => node.Month)

    //     const minRentTickValue = (Math.min(...rentRoll)) / 2
    //     const maxRentValue = Math.max(...rentRoll)
    //     const minRentValue = Math.min(...rentRoll)
    //     const rentRollRange = (maxRentValue - minRentValue)
    //     const rentRollStepSize = rentRollRange / 2

    //     const minIncomeTickValue = (Math.min(...income)) / 2
    //     const maxIncomeValue = Math.max(...income)
    //     const minIncomeValue = Math.min(...income)
    //     const incomeRange = (maxIncomeValue - minIncomeValue)
    //     const incomeStepSize = incomeRange / 2

    //     this.options.scales.yAxes[0].ticks.min = minRentTickValue
    //     this.options.scales.yAxes[0].ticks.max = maxRentValue * 1.15

    //     this.options.scales.yAxes[1].ticks.min = minIncomeTickValue
    //     this.options.scales.yAxes[1].ticks.max = maxIncomeValue *1.15

    //     this.chartdata.datasets[0].data = rentRoll.reverse()
    //     this.chartdata.datasets[1].data = income.reverse()
    //     this.chartdata.labels = labels.reverse()

    //     // this.options.scales.yAxes[0].ticks.stepSize = maxRentValue / 6
    //     // this.options.scales.yAxes[1].ticks.stepSize = Math.round(incomeStepSize / 1000) * 1000
    //   })
    //   .then(() => {
    //       this.renderChart(this.chartdata, this.options)
    //   })
    //   .catch(err => console.log('There s an error with the graph:' + err))
    // }
  },
  watch: {
    portfolioGrowth(newValue, oldValue) {
      // console.log(`Updating from ${oldValue} to ${newValue}`)
      if(newValue != oldValue) {
        this.setChart()
        this.renderChart(this.chartdata, this.options)
      }
    }
  },
}
</script>

<style scoped>

</style>
